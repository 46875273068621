import {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {ethers} from "ethers";
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import KatsuLandClient from '../../services/katsuland'

function VerificationPage(props) {
  const { sessionUuid } = useParams()
  
  const [isConnected, setIsConnected] = useState(false)
  const [provider, setProvider] = useState(null)
  const [walletAddress, setWalletAddress] = useState("")
  const [canVerify, setCanVerify] = useState(false)
  const [isVerifying, setIsVerifying] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  
  useEffect(() => {
    const checkWalletConnect = async () => {
      if (window.ethereum) {
        const currentProvider = new ethers.providers.Web3Provider(window.ethereum)
        setProvider(currentProvider)
  
        const accounts = await currentProvider.listAccounts()
        if (accounts.length > 0) {
          const currentSigner = await currentProvider.getSigner()
          const addr = await currentSigner.getAddress()
    
          setIsConnected(true)
          setWalletAddress(addr)
        }
      }
    }
  
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', async (accounts) => {
        checkWalletConnect()
      });
    }
    
    // Check if sessionUuid can be used for verification
    KatsuLandClient.canVerify(sessionUuid)
      .then(res => {
        if (!res.data.content.isValid) {
          toast.warning('The given session ID cannot be used, please request for a new one')
          setCanVerify(false)
          return
        }
        setCanVerify(true)
      })
      .catch(err => {
        setCanVerify(false)
        if (err.response.status !== 500) {
          toast.warning(err.response.data.message)
        } else {
          toast.error('Unexpected error, please try again')
        }
      })
  
    checkWalletConnect()
  }, [sessionUuid, props.history])
  
  const connectWallet = async () => {
    if (!isConnected) {
      const accounts = await provider.send("eth_requestAccounts", [])
      if (accounts && accounts.length > 0) {
        const currentSigner = await provider.getSigner()
        const addr = await currentSigner.getAddress()
        
        setIsConnected(true)
        setWalletAddress(addr)
      }
    }
  }
  
  const requestAndSendSignature = async (msg) => {
    if (isConnected && provider) {
      setIsVerifying(true)
      
      const signer = await provider.getSigner()
      
      let signature = ''
      try {
        signature = await signer.signMessage(msg)
      } catch (err) {
        setIsVerifying(false)
        toast.warn('Verification cancelled')
        return
      }
  
      try {
        await KatsuLandClient.verify(sessionUuid, signature)
        setIsVerified(true)
        toast.success('Verification successful! Your role has been granted in the relevant Discord server')
      } catch (err) {
        switch (err.response.status) {
          case 400:
            toast.warn(err.response.data.message)
            break
          case 401:
            toast.warn('Verification failed. If you initiated verification with a new wallet address, please wait for 5 minutes before trying again')
            break
          default:
            toast.error('Unexpected error. Role sequence might not be configured correctly, please contact Discord server admin')
            break
        }
      }
  
      setIsVerifying(false)
    }
  }
  
  return (
    <div className="home-page">
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-0">
        <div className="cover mobile h-screen hidden xl:block" style={{
          background: `url(/img/katsu-land-cover.jpeg) no-repeat`,
          backgroundSize: 'cover',
        }} />
        
        <div className="cover desktop h-auto block xl:hidden">
          <figure>
            <img src="/img/katsu-land-cover.jpeg"  alt="Katsu.Land Cover" className="min-w-full" />
          </figure>
        </div>
  
        <div className="cover-text flex flex-col justify-center text-center text-primary px-3 py-10">
          { window.ethereum ? (
            <>
              { canVerify ? (
                <div className="buttons font-thin">
                  <div className="mb-5">
                    <button className="inline-block bg-primary text-white font-thin px-5 py-3" onClick={connectWallet}>{isConnected ? walletAddress : "Connect Wallet"}</button>
                  </div>
                  { isConnected ? (
                    <div className="mb-5">
                      <button className="inline-block bg-complementary text-white font-thin px-5 py-3" onClick={async () => { await requestAndSendSignature(sessionUuid) }} disabled={isVerifying || isVerified}>
                        {isVerified ? 'Verified Successfully! ' : 'Click to Verify '}
                        {isVerifying ? (<FontAwesomeIcon icon="circle-notch" spin className="mr-2" />) : ''}
                      </button>
                    </div>
                  ) : '' }
                  <div className="hover:underline">
                    <a href="https://docs.cattokatsu.com/katsu.land/introduction" target="_blank" rel="noreferrer">User Guide & Documentation</a>
                  </div>
                </div>
              ) : (
                <p className="font-thin">Given session cannot be used, please request a new one in the relevant Discord channel</p>
              ) }
            </>
          ) : (
            <div className="no-mm">
              <p>No Metamask detected</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default VerificationPage;
