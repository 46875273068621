function HomePage(props) {
  const now = new Date(Date.now())
  return (
    <div className="home-page">
      <section className="cover-section grid grid-cols-1 xl:grid-cols-2 gap-0">
        <div className="cover mobile h-screen hidden xl:block" style={{
          background: `url(/img/katsu-land-cover.jpeg) no-repeat`,
          backgroundSize: 'cover',
        }} />
  
        <div className="cover desktop h-auto block xl:hidden">
          <figure>
            <img src="/img/katsu-land-cover.jpeg"  alt="Katsu.Land Cover" className="min-w-full" />
          </figure>
        </div>
        
        <div className="cover-text flex flex-col justify-center text-center text-primary px-3 py-10">
          <h1 className="font-medium text-3xl mb-5">Discord NFT Verification Bot</h1>
          <p className="mb-5 font-light">Fantom<br/>Harmony One<br/>Avalanche<br/>Cronos<br/>Metis<br/>Moonbeam<br/>Ethereum<br/>Polygon</p>
          <div className="buttons font-thin">
            <div className="mb-3 hover:underline">
              <a href="https://docs.cattokatsu.com/katsu.land/introduction" target="_blank" rel="noreferrer">User Guide & Documentation</a>
            </div>
            <div className="mb-3">
              <a href="https://discord.com/api/oauth2/authorize?client_id=915659553830494208&permissions=268437504&scope=bot%20applications.commands" target="_blank" rel="noreferrer"
                 className="inline-block bg-primary text-white px-5 py-3">Invite Bot</a>
            </div>
          </div>
        </div>
      </section>
      
      <section className='features-section mt-10'>
        <h2 className="text-primary font-medium text-3xl mb-10"><img src='/img/whatsthere.png' alt='' className='inline-block w-12 mr-3' /> Features You'll Love</h2>
        
        <div className='max-w-screen-sm mx-auto'>
          <article className='feature mb-5'>
            <h3 className='text-complementary text-xl mb-3'>Effortless Token Holder Verifications</h3>
            <p className='font-light'>Allow your token holders to gain exclusive access to your Discord holders-only channels via an easy, self-served manner</p>
          </article>
  
          <article className='feature mb-5'>
            <h3 className='text-complementary text-xl mb-3'>One Bot, Many Chains, for the Underserved Communities</h3>
            <p className='font-light'>The bot exists for the underserved NFT communities. Besides <b>Ethereum</b>, the bot also supports <b>Fantom</b>, <b>Harmony One</b>, <b>Avalanche</b>, and counting. Perfect for multi-chain NFT Discord communities</p>
          </article>
  
          <article className='feature mb-5'>
            <h3 className='text-complementary text-xl mb-3'>Holder Status Monitoring & Automatic Role Removal</h3>
            <p className='font-light'>Token sold or transferred? No problem, the bot will remove the role upon detection.</p>
          </article>
  
          <article className='feature mb-5'>
            <h3 className='text-complementary text-xl mb-3'>Go Wild, Go Premium</h3>
            <p className='font-light'>Want multiple integrations within the same Discord server? No problem, <b><a href='https://cattokatsu.com/' target='_blank' rel='noreferrer'><span className='text-primary'>mint</span></a> or get a Catto Katsu NFT</b> from secondary markets and link it to your Discord server!</p>
          </article>
        </div>
      </section>
      
      <footer className='mt-10 py-3 font-thin text-sm' style={{background: '#f8f8f8'}}>
        &copy; Katsu.Land {now.getFullYear()}
      </footer>
    </div>
  );
}

export default HomePage;
