const axios = require('axios')
const httpClient = axios.create({
  baseURL: process.env.REACT_APP_KATSU_LAND_API_URL
});

module.exports = {
  
  canVerify: (sessionUuid => {
    return httpClient.post(`/sessions/${sessionUuid}/can-verify`)
  }),
  
  verify: (sessionUuid, signature) => {
    return httpClient.post(`/sessions/${sessionUuid}/verify`, {signature})
  },
  
  canActivatePremium: (requestUuid => {
    return httpClient.post(`/premium/${requestUuid}/can-activate`)
  }),
  
  activatePremium: (requestUuid, signature) => {
    return httpClient.post(`/premium/${requestUuid}/activate`, {signature})
  },
  
}
