import './App.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { library as faLib } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import HomePage from "./components/home/HomePage";
import VerificationPage from "./components/verification/VerificationPage";
import PremiumActivationPage from "./components/premium/PremiumActivationPage";

faLib.add(fab, fas)

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/verification/:sessionUuid" component={VerificationPage} />
          <Route exact path="/premium-activation/:requestUuid" component={PremiumActivationPage} />
          <Route exact path="/" component={HomePage} />
        </Switch>
        <ToastContainer position="bottom-center" autoClose={5000}/>
      </div>
    </Router>
  );
}

export default App;
